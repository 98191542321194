.container {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

button.detailsBtn {
  font-size: 1.2em;
  margin: 2em 0;
}

.errorDiv {
  white-space: pre-wrap;
  font-family: monospace;
}
